import 'ui/styles/global/global-styles.scss';
import '@fontsource/quicksand/300.css';
import '@fontsource/quicksand/500.css';
import '@fontsource/quicksand/600.css';
import '@fontsource/quicksand/700.css';
import { BasketProvider } from 'components/templates/basket';
import App from 'next/app';
import { simplyFetchFromGraph } from '../lib/graph';
import fragments from '../lib/graph/fragments';
import ErrorBoundary from '../page-components/error-boundary/ErrorBoundary';

const FOOTER_DOCS_QUERY = `
  query getFooterDocs{
    catalogue(language: "no", path: "/om") {
      path
      children{
        path
        name
        children{
          ...item
          ...product
        }
      }
    }
  }
  ${fragments}
`;

// get footer's data for every single page
MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);

  const { data: { catalogue: { children: footerDocsBundle } } } = await simplyFetchFromGraph({
    query: FOOTER_DOCS_QUERY,
  });

  return {
    ...appProps,
    footerDocs: footerDocsBundle,
  };
};

export default function MyApp({ Component, pageProps, footerDocs }) {
  return (
    <BasketProvider locale="no">
      <ErrorBoundary>
        <Component {...pageProps} footerDocs={footerDocs} />
      </ErrorBoundary>
    </BasketProvider>
  );
}
