// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { Integrations } from '@sentry/nextjs/dist/index.client';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://b5ef826b6c7141a78cc608d53101fc51@o1031955.ingest.sentry.io/5998876',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  integrations: [
    new Integrations.BrowserTracing({
      // this is a hack and should be avoided
      // Tracing origins contains a list of
      /*
        The default value of tracingOrigins is ['localhost', /^\//].
        The JavaScript SDK will attach the sentry-trace header to all outgoing XHR/fetch
        requests whose destination contains a string in the list or matches a regex in the list.
        If your frontend is making requests to a different domain, you will need to add it there to
        propagate the sentry-trace header to the backend services, which is required to link transactions
        together as part of a single trace. The tracingOrigins option matches against the whole request URL,
        not just the domain. Using stricter regex to match certain parts of the URL ensures that requests
        do not unnecessarily have the sentry-trace header attached.
      */
      tracingOrigins: ['why.do.i.have.to.do.this'],
    }),
  ],
});
