import React from 'react';
import Layout from '../../components/templates/layout';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
    console.error(errorInfo);
    // this.setState({
    //   hasError: true,
    //   error: error
    // })
    // TODO: log to sentry
  }

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <Layout>
          <h1>Oops! Something went wrong.</h1>
          <h2>Brace yourself till our team gets the error fixed.</h2>
          <br />
          <i>
            Error:
            {error.message}
          </i>
          <br />
          <hr />
          <br />
          <div> </div>
          <div>You may also refresh the page or try again later.</div>
          <div>If the problem persist, please send us an email at: myrevolution@gmail.com</div>
          <br />

          <button type="button" className="btn btnBlack" onClick={() => { window.location.href = '/'; }}>← Go back to the homepage</button>
        </Layout>
      );
    }

    return children;
  }
}
